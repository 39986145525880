@use "sass:map";
@use "app/var.module";

$gap-height: 3px;

.field {
  position: relative;
  width: 100%;
  height: 5px;
  background-color: map.get(var.$colors, 'orange');

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map.get(var.$colors, 'orange');
  }

  .scene {
    margin-left: 0px;
    margin-left: 0px;
    position: absolute;
    top: 0%;
    left: 50%;
    width: 10px;
    height: 100%;
    transform: translate(-50%, 0%);
  }
}
