@use "sass:map";
@use "app/var.module";

$gap-height: 3px;

.cloudscape {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .container {
    position: absolute;
    top: 0px;
    left: -955px;
    width: 1920px;
    height: 287px;

    &.offscreen {
      left: -2875px;
    }

    & > div {
      transform: translate3d(0, 0, 0);
      will-change: transform;
    }

    .cloud01 {
      position: absolute;
      top: 55px;
      left: 160px;
      width: 120px;
      height: 48px;

      &::before {
        content: '';
        position: absolute;
        top: 60%;
        left: 0%;
        width: 100%;
        height: 40%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 55% 85%;
        border-top-right-radius: 55% 85%;
      }

      & .c01 {
        position: absolute;
        top: 48%;
        left: 8%;
        width: 92%;
        height: 47%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 30% 66%;
        border-bottom-left-radius: 15% 30%;
        border-top-right-radius: 35% 90%;

        &::before {
          content: '';
          position: absolute;
          bottom: -14%;
          left: -4%;
          width: 104%;
          height: 59%;
          background-color: map.get(var.$colors, 'orange');
          border-top-left-radius: 15% 50%;
          border-top-right-radius: 10% 90%;
        }
      }

      & .c02 {
        position: absolute;
        top: 30%;
        left: 20%;
        width: 65%;
        height: 44%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 18% 50%;
        border-top-right-radius: 12% 50%;
        border-bottom-right-radius: 10% 45%;

        &::before {
          content: '';
          position: absolute;
          bottom: -28%;
          left: -5%;
          width: 108%;
          height: 59%;
          background-color: map.get(var.$colors, 'orange');
          border-top-left-radius: 15% 10%;
          border-top-right-radius: 34% 12%;
        }
      }

      & .c03 {
        position: absolute;
        top: 0%;
        left: 32%;
        width: 42%;
        height: 80%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-radius: 45%;

        &::before {
          content: '';
          position: absolute;
          bottom: -20%;
          left: -11%;
          width: 120%;
          height: 75%;
          background-color: map.get(var.$colors, 'orange');
        }
      }

      &.pos01 {
        top: 108px;
        left: 1325px;
      }
    }

    .cloud02 {
      position: absolute;
      top: 160px;
      left: 475px;
      width: 110px;
      height: 38px;
      // border: 1px solid black;

      &::before {
        content: '';
        position: absolute;
        bottom: 0%;
        left: 0%;
        width: 100%;
        height: 20%;
        background-color: map.get(var.$colors, 'orange');
        border-bottom: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 45% 80%;
        border-top-right-radius: 40% 85%;
      }

      .c01 {
        position: absolute;
        bottom: 0%;
        left: 0%;
        width: 100%;
        height: 45%;

        &::before {
          content: '';
          position: absolute;
          bottom: 0%;
          left: 0%;
          width: 36%;
          height: 78%;
          background-color: map.get(var.$colors, 'orange');
          border: $gap-height solid map.get(var.$colors, 'white');
          border-top-left-radius: 60% 90%;
          border-top-right-radius: 50% 85%;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0%;
          right: 0%;
          width: 22%;
          height: 64%;
          background-color: map.get(var.$colors, 'orange');
          border: $gap-height solid map.get(var.$colors, 'white');
          border-top-left-radius: 48% 95%;
          border-top-right-radius: 90% 125%;
        }
      }

      .c02 {
        position: absolute;
        bottom: 0%;
        left: 24%;
        width: 32%;
        height: 62%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 45% 65%;

        &::before {
          content: '';
          position: absolute;
          bottom: 0%;
          left: -18%;
          width: 30%;
          height: 40%;
          background-color: map.get(var.$colors, 'orange');
        }
      }

      .c03 {
        position: absolute;
        top: 0%;
        right: 15%;
        width: 50%;
        height: 100%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 65% 88%;
        border-top-right-radius: 65% 80%;
        border-bottom-right-radius: 10% 50%;

        &::before {
          content: '';
          position: absolute;
          bottom: 0%;
          left: 86%;
          width: 22%;
          height: 16%;
          background-color: map.get(var.$colors, 'orange');
          border-top-right-radius: 25%;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0%;
          left: -10%;
          width: 22%;
          height: 55%;
          background-color: map.get(var.$colors, 'orange');
          border-top-left-radius: 40%;
        }
      }

      &.pos01 {
        top: 238px;
        left: 1725px;
      }
    }
  }
}

/**
 * Keyframe animations
 */

.float {
  animation-iteration-count: infinite;
  animation-duration: 500s;
  animation-name: float-background;
  animation-timing-function: linear;
  will-change: transform;

  @media print {
    animation: none;
  }
}

@keyframes float-background {
  0% {
    transform: translate3d(0px, 0, 0);
  }

  100% {
    transform: translate3d(1920px, 0, 0);
  }
}
