@use "sass:map";
@use "app/var.module";

$gap-height: 3px;

.field {
  position: relative;
  width: 100%;
  height: 7.5px;
  background-color: map.get(var.$colors, 'red');

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map.get(var.$colors, 'red');
  }

  .scene {
    margin-left: 0px;
    position: absolute;
    width: 10px;
    height: 100%;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
