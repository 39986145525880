@use "sass:map";
@use "app/var.module";

$hero-height: 450px;
$small-gap-height: 3px;
$gap-height: 6px;

.heroContainer {
  position: relative;
  width: 100%;
  height: $hero-height;
  overflow: hidden;
}

.spacer {
  position: relative;
  width: 100%;
  height: $gap-height;
  background-color: map.get(var.$colors, 'white');

  &.small {
    height: $small-gap-height;
  }
}

.logoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .logo {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translate(-50%, -30%);
  }

  .hexBackdrop {
    position: absolute;
    top: 251px;
    left: 50%;
    height: 108px;
    width: 184px;
    transform: translate(-50%, -14%);
    background-color: map.get(var.$colors, 'white');
    border-radius: 2px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: map.get(var.$colors, 'white');
      border-radius: 2px;
      height: 100%;
    }

    &::before {
      transform: rotate(60deg);
    }

    &::after {
      transform: rotate(-60deg);
    }
  }
}
