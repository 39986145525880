@use "sass:map";
@use "app/var.module";

// $upper-gap-height: 12px;
// $lower-gap-height: 24px;
$upper-gap-height: 1.35%; // as a % of the width of the inner hex
$lower-gap-height: 2.70%; // as a % of the width of the inner hex
// $outer-hex-diff-horizontal: 18px;
// $outer-hex-diff-vertical: 19px;
$outer-hex-diff-horizontal: 1.94%; // as a % of the width of the outer container
$outer-hex-diff-vertical: 1.8%; // as a % of the height of the outer container

// Height = 1.15 x Width (use Math.round)
// Original width: 926px;
// Original height: 1065px;

.logoContainer {
  position: relative;
}

.outerHex {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & .top {
    position: relative;
    width: 100%;
    height: 26.29%;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 56.5%;
      width: 77%;
      height: 200%;
      border-radius: 5% / 10%;
      background-color: map.get(var.$colors, "white");
    }

    &::before {
      left: 5%;
      transform: rotate(61deg);
      border-top-left-radius: 5%;
    }

    &::after {
      left: 18%;
      transform: rotate(-61deg);
      border-top-right-radius: 5%;
    }
  }

  & .middle {
    width: 100%;
    height: 48.54%;
    background-color: map.get(var.$colors, "white");
  }

  & .bottom {
    position: relative;
    width: 100%;
    height: 25.17%;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 57%;
      width: 76%;
      height: 211%;
      border-radius: 9% / 6%;
      background-color: map.get(var.$colors, "white");
    }

    &::before {
      left: 19%;
      transform: rotate(61deg);
      border-bottom-right-radius: 5%;
    }

    &::after {
      left: 5%;
      transform: rotate(-61deg);
      border-bottom-left-radius: 5%;
    }
  }
}

.innerHex {
  position: relative;
  top: $outer-hex-diff-vertical;
  left: $outer-hex-diff-horizontal;
  margin: 0;
  width: 96.11%;
  height: 96.06%;
}

.hexMaroonContainer {
  position: relative;
  width: 100%;
  height: 48.68%;
  transform-style: preserve-3d;
  overflow: hidden;

  .hexMaroon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & .top {
      position: relative;
      width: 100%;
      height: 53.01%;
      overflow: hidden;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 59%;
        width: 77%;
        height: 201%;
        border-radius: 4% / 7%;
        background-color: map.get(var.$colors, "maroon");
      }

      &::before {
        left: 5%;
        transform: rotate(61deg);
        border-top-left-radius: 4%;
      }

      &::after {
        left: 18%;
        transform: rotate(-61deg);
        border-top-right-radius: 4%;
      }
    }

    .bottom {
      width: 100%;
      height: 46.99%;
      background-color: map.get(var.$colors, "maroon");
    }
  }

  & .egret {
    top: 28.3%;
    left: 12.55%;
  }
}

.hexRedContainer {
  position: relative;
  width: 100%;
  height: 4.2%;
  transform-style: preserve-3d;
  overflow: hidden;

  .hexRed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map.get(var.$colors, "red");
  }

  .egret {
    top: -863%;
    left: 12.55%;
  }
}

.hexOrangeContainer {
  position: relative;
  width: 100%;
  height: 2.93%;
  border-bottom-left-radius: 1.5% 40%;
  border-bottom-right-radius: 1.5% 40%;
  transform-style: preserve-3d;
  overflow: hidden;

  .hexOrange {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map.get(var.$colors, "orange");
  }

  .egret {
    top: -1427%;
    left: 12.55%;
  }
}

.hexBlueContainer {
  position: relative;
  width: 100%;
  height: 39.59%;
  transform-style: preserve-3d;
  overflow: hidden;

  .hexBlue {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & .top {
      width: 100%;
      height: 34.57%;
      border-top-left-radius: 1.5% 10%;
      border-top-right-radius: 1.5% 10%;
      background-color: map.get(var.$colors, "blue");
    }

    & .bottom {
      position: relative;
      width: 100%;
      height: 65.43%;
      overflow: hidden;

      &::before,
      &::after {
        content: '';
        width: 78%;
        height: 201%;
        bottom: 60%;
        position: absolute;
        background-color: map.get(var.$colors, "blue");
        border-radius: 4%;
      }

      &::before {
        left: 17%;
        transform: rotate(61deg);
        border-bottom-right-radius: 5%;
      }

      &::after {
        left: 5%;
        transform: rotate(-61deg);
        border-bottom-left-radius: 5%;
      }
    }
  }
  
  .egret {
    top: -120%;
    left: 12.55%;
  }
}

.spacer {
  width: 100%;
  height: $lower-gap-height;

  &.small {
    height: $upper-gap-height;
  }
}

.egret {
  position: absolute;
  top: 15%;
  left: 14%;
}

.frontFace {
  transform: rotateY(0deg);
}

.backFace {
  transform: rotateY(180deg);
}

/**
 * Print styles
 */

@media print {
  .spin {
    animation: none;
  }
}

/**
 * Keyframe animations
 */

$animation-delay: 75ms;

.spin {
  animation-name: spin;
  animation-duration: 9000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.35, 1.64, 0.41, 0.8);

  @media print {
    animation: none;
  }
}

.delay1 {
  animation-delay: calc($animation-delay * 1);
}

.delay2 {
  animation-delay: calc($animation-delay * 2.25);
}

.delay3 {
  animation-delay: calc($animation-delay * 3.75);
}

@keyframes spin {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
    filter: grayscale(0);
  }

  94% {
    opacity: 1;
    transform: rotateY(0deg);
    filter: grayscale(0);
  }

  97% {
    opacity: 0.9;
    filter: grayscale(0.4);
  }

  100% {
    opacity: 1;
    transform: rotateY(360deg);
    filter: grayscale(0);
  }
}
