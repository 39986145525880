@use "sass:map";
@use "app/var.module";

.field {
  position: relative;
  width: 100%;
  height: 138px;
  background-color: map.get(var.$colors, 'blue');
  overflow: hidden;

  .hex {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 27px;
    width: 46px;
    transform: rotateX(60deg) translate(-50%, 15%);
    background-color: map.get(var.$colors, 'white');
    border-radius: 3%;
    opacity: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: map.get(var.$colors, 'white');
      border-radius: 3%;
      height: 100%;
    }

    &::before {
      transform: rotate(60deg);
    }

    &::after {
      transform: rotate(-60deg);
    }
  }
}

/**
 * Keyframe animations
 */

$animation-delay: 600ms;

.ripple {
  animation-iteration-count: infinite;
  animation-duration: 9000ms;
  animation-name: ripple;
  animation-timing-function: ease-out;
  animation-delay: $animation-delay;

  &.delay1 {
    animation-delay: calc($animation-delay * 1.625);
  }

  &.delay2 {
    animation-delay: calc($animation-delay * 2.125);
  }

  @media print {
    animation: none;
  }
}

@keyframes ripple {
  0% {
    height: 27px;
    width: 46px;
    transform: rotateX(60deg) translate(-50%, 15%);
    opacity: 0;
  }

  90% {
    height: 27px;
    width: 46px;
    transform: rotateX(60deg) translate(-50%, 15%);
    opacity: 0;
  }

  94% {
    opacity: 0.15;
  }

  99% {
    opacity: 0;
  }

  100% {
    height: 108px;
    width: 184px;
    transform: rotateX(60deg) translate(-50%, -70%);
    opacity: 0;
  }
}
