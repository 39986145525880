@use "sass:map";
@use "app/var.module";

$gap-height: 3px;

.cityscape {
  position: absolute;
  left: -582px;
  bottom: 0;
  width: 400px;
  height: 295px;

  .cappedBldg {
    position: absolute;
    bottom: 0;
    left: 244px;
    width: 45px;
    height: 78px;
    background-color: map.get(var.$colors, 'red');
    border-top: $gap-height solid map.get(var.$colors, 'white');
    border-right: $gap-height solid map.get(var.$colors, 'white');
    border-left: $gap-height solid map.get(var.$colors, 'white');

    &::before {
      content: '';
      position: absolute;
      width: 39px;
      height: 8px;
      left: 0px;
      top: -8px;
      background-color: map.get(var.$colors, 'red');
      border-top: $gap-height solid map.get(var.$colors, 'white');
      border-right: $gap-height solid map.get(var.$colors, 'white');
      border-left: $gap-height solid map.get(var.$colors, 'white');
    }

    &.bldg01 {
      left: 0px;
      width: 60px;
      height: 52px;

      &::before {
        height: 12px;
        left: -3px;
        top: -12px;
      }
    }

    &.bldg02 {
      left: 323px;
      width: 42px;
      height: 64px;

      &::before {
        width: 34px;
        height: 22px;
        left: 5px;
        top: -22px;
      }
    }
  }

  .domedBldg {
    position: absolute;
    bottom: 0;
    left: 368px;
    width: 30px;
    height: 75px;
    background-color: map.get(var.$colors, 'red');
    border-top: $gap-height solid map.get(var.$colors, 'white');
    border-right: $gap-height solid map.get(var.$colors, 'white');
    border-left: $gap-height solid map.get(var.$colors, 'white');

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      left: 1px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: map.get(var.$colors, 'red');
      border-top: $gap-height solid map.get(var.$colors, 'white');
      border-right: $gap-height solid map.get(var.$colors, 'white');
      border-left: $gap-height solid map.get(var.$colors, 'white');
    }

    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: map.get(var.$colors, 'red');
    }

    &.bldg01 {
      left: 98px;
      width: 42px;
      height: 41px;

      &::before {
        top: -20px;
        width: 34px;
        height: 34px;
      }
    }
  }

  .flatBldg {
    position: absolute;
    bottom: 0;
    left: 291px;
    width: 30px;
    height: 50px;
    background-color: map.get(var.$colors, 'red');
    border-top: $gap-height solid map.get(var.$colors, 'white');
    border-right: $gap-height solid map.get(var.$colors, 'white');
    border-left: $gap-height solid map.get(var.$colors, 'white');

    &.bldg01 {
      left: 64px;
      width: 32px;
      height: 72px;
    }

    &.bldg02 {
      left: 144px;
      width: 35px;
      height: 42px;
    }
  }

  .taipei101 {
    position: absolute;
    bottom: 0;
    left: 182px;
    width: 60px;
    height: 270px;

    & .base {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60%;
      height: 55px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: map.get(var.$colors, 'red');
        border-top: $gap-height solid map.get(var.$colors, 'white');
      }

      &::before {
        left: 0%;
        transform: skewX(-5deg);
        border-left: $gap-height solid map.get(var.$colors, 'white');
      }

      &::after {
        left: 66%;
        transform: skewX(5deg);
        border-right: $gap-height solid map.get(var.$colors, 'white');
      }
    }

    & .level {
      position: absolute;
      left: 0;
      width: 60%;
      height: 25px;

      &.one {
        bottom: 52px;
      }

      &.two {
        bottom: 74px;
      }

      &.three {
        bottom: 96px;
      }

      &.four {
        bottom: 118px;
      }

      &.five {
        bottom: 140px;
      }

      &.six {
        bottom: 162px;
      }

      &.seven {
        bottom: 184px;
      }

      &.eight {
        bottom: 206px;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: map.get(var.$colors, 'red');
        border-top: $gap-height solid map.get(var.$colors, 'white');
      }

      &::before {
        left: 10%;
        transform: skewX(8deg);
        border-left: $gap-height solid map.get(var.$colors, 'white');
      }

      &::after {
        left: 56%;
        transform: skewX(-8deg);
        border-right: $gap-height solid map.get(var.$colors, 'white');
      }
    }

    & .topBase {
      position: absolute;
      left: 17px;
      bottom: 228px;
      width: 40%;
      height: 10px;
      background-color: map.get(var.$colors, 'red');
      border-top: $gap-height solid map.get(var.$colors, 'white');
      border-left: $gap-height solid map.get(var.$colors, 'white');
      border-right: $gap-height solid map.get(var.$colors, 'white');

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -20px;
        width: 55%;
        height: 20px;
        background-color: map.get(var.$colors, 'red');
        border-top: $gap-height solid map.get(var.$colors, 'white');
      }

      &::before {
        left: 1px;
        transform: skewX(6deg);
        border-left: $gap-height solid map.get(var.$colors, 'white');
      }

      &::after {
        left: 42%;
        transform: skewX(-6deg);
        border-right: $gap-height solid map.get(var.$colors, 'white');
      }
    }

    & .antenna {
      position: absolute;
      left: 23px;
      bottom: 252px;
      width: 20%;
      height: 8px;
      background-color: map.get(var.$colors, 'red');
      border-top: $gap-height solid map.get(var.$colors, 'white');
      border-left: $gap-height solid map.get(var.$colors, 'white');
      border-right: $gap-height solid map.get(var.$colors, 'white');

      &::before {
        content: '';
        position: absolute;
        left: 1px;
        bottom: 5px;
        width: 4px;
        height: 35px;
        background-color: map.get(var.$colors, 'white');
      }
    }
  }
}
