@use "sass:map";
@use "app/var.module";

$content-padding: map.get(var.$content, 'padding');

$stackContainer-breakpoint-small-max: 29.3125rem;
$stackContainer-breakpoint-medium-min: 29.375rem;
$stackContainer-breakpoint-medium-max: 46.8125rem;

.tagline {
  margin-bottom: 4rem;
  text-align: center;
  font-size: 1.25rem;
  font-style: italic;
}

.fullStackContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: center;

  .stackContainer {
    padding: $content-padding;
    width: 33.33%;
    flex-grow: 1;

    .stack {
      width: 100%;

      .title {
        margin: 0 0 $content-padding 0;
        padding: calc($content-padding / 2) $content-padding;
        font-size: 1.25rem;
      }

      .link {
        position: relative;
        padding: calc($content-padding / 2) $content-padding;
        font-size: 1.17rem;
      }
    }

    @media screen and (max-width: $stackContainer-breakpoint-small-max) {
      width: 100%;
    }

    @media screen and (min-width: $stackContainer-breakpoint-medium-min) and (max-width: $stackContainer-breakpoint-medium-max) {
      width: 50%;
    }
  }
}

.printPageBreak {
  @media print {
    margin-top: calc($content-padding * 8);
  }
}
