@use "sass:map";
@use "app/var.module";

$gap-height: 3px;

.cloudscape {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .container {
    position: absolute;
    top: -300px;
    left: -955px;
    width: 1920px;
    height: 450px;

    &.offscreen {
      left: -2875px;
    }

    & > div {
      transform: translate3d(0, 0, 0);
      will-change: transform;
    }

    .cloud01 {
      position: absolute;
      top: 235px;
      left: 70px;
      width: 70px;
      height: 25px;

      &::before {
        content: '';
        position: absolute;
        top: 13px;
        left: 0px;
        width: 100%;
        height: 48%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-right-radius: 15% 60%;
        border-bottom-right-radius: 6% 20%;
        border-top-left-radius: 30% 100%;
      }

      & .c01 {
        position: absolute;
        top: 10%;
        left: 24%;
        width: 60%;
        height: 66%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 40% 100%;
        border-top-right-radius: 40% 100%;

        &::before {
          content: '';
          position: absolute;
          top: 99%;
          left: -11%;
          width: 122%;
          height: 62%;
          background-color: map.get(var.$colors, 'orange');
        }
      }

      &.pos01 {
        top: 200px;
        left: 750px;
        width: 80px;
        height: 30px;

        & .c01 {
          top: -1%;

          &::before {
            height: 55%;
          }
        }
      }

      &.pos02 {
        top: 256px;
        left: 1255px;
      }
    }

    .cloud02 {
      position: absolute;
      top: 190px;
      left: 295px;
      width: 90px;
      height: 36px;

      &::before {
        content: '';
        position: absolute;
        top: 24px;
        left: 0px;
        width: 100%;
        height: 33.33%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 30% 65%;
        border-bottom-left-radius: 12% 16%;
        border-bottom-right-radius: 25% 85%;
      }

      .c01 {
        position: absolute;
        top: 28%;
        left: 20%;
        width: 40%;
        height: 65%;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          background-color: map.get(var.$colors, 'orange');
          border: $gap-height solid map.get(var.$colors, 'white');
          border-top-left-radius: 80% 100%;
          border-top-right-radius: 50% 50%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 73%;
          left: 0%;
          width: 100%;
          height: 25%;
          background-color: map.get(var.$colors, 'orange');
        }
      }

      .c02 {
        position: absolute;
        top: 40%;
        left: 61%;
        width: 39%;
        height: 52%;

        &::before {
          content: '';
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 75%;
          background-color: map.get(var.$colors, 'orange');
          border-top: $gap-height solid map.get(var.$colors, 'white');
          border-right: $gap-height solid map.get(var.$colors, 'white');
          border-top-right-radius: 50% 85%;
          border-bottom-right-radius: 50% 50%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 17%;
          right: 7%;
          width: 60%;
          height: 75%;
          background-color: map.get(var.$colors, 'orange');
          border-radius: 50%;
        }
      }

      .c03 {
        position: absolute;
        top: 5%;
        left: 46%;
        width: 32%;
        height: 82%;

        &::before {
          content: '';
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          background-color: map.get(var.$colors, 'orange');
          border: $gap-height solid map.get(var.$colors, 'white');
          border-top-left-radius: 60% 45%;
          border-top-right-radius: 65% 65%;
          border-bottom-right-radius: 65% 65%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 52%;
          left: 0%;
          width: 100%;
          height: 50%;
          background-color: map.get(var.$colors, 'orange');
        }
      }

      .c04 {
        position: absolute;
        top: 36%;
        left: 44%;
        width: 20%;
        height: 30%;
        background-color: map.get(var.$colors, 'orange');
      }
    }

    .cloud03 {
      position: absolute;
      top: 225px;
      left: 1045px;
      width: 90px;
      height: 32px;

      &::before {
        content: '';
        position: absolute;
        top: 59%;
        left: 0%;
        width: 100%;
        height: 40%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 15% 80%;
        border-top-right-radius: 15% 80%;
      }

      .c01 {
        position: absolute;
        top: 32%;
        left: 20%;
        width: 26%;
        height: 54%;
        background-color: map.get(var.$colors, 'orange');
        border: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 50% 65%;

        &::before {
          content: '';
          position: absolute;
          top: 80%;
          left: -19%;
          width: 138%;
          height: 50%;
          background-color: map.get(var.$colors, 'orange');
        }
      }

      .c02 {
        position: absolute;
        top: 40%;
        left: 62%;
        width: 26%;
        height: 37%;
        background-color: map.get(var.$colors, 'orange');
        border-top: $gap-height solid map.get(var.$colors, 'white');
        border-left: $gap-height solid map.get(var.$colors, 'white');
        border-right: $gap-height solid map.get(var.$colors, 'white');
        border-top-right-radius: 65% 85%;

        &::before {
          content: '';
          position: absolute;
          top: 73%;
          left: -30%;
          width: 160%;
          height: 60%;
          background-color: map.get(var.$colors, 'orange');
          border-top-right-radius: 15% 80%;
        }
      }

      .c03 {
        position: absolute;
        top: 0%;
        left: 36%;
        width: 40%;
        height: 70%;
        background-color: map.get(var.$colors, 'orange');
        border-top: $gap-height solid map.get(var.$colors, 'white');
        border-left: $gap-height solid map.get(var.$colors, 'white');
        border-right: $gap-height solid map.get(var.$colors, 'white');
        border-top-left-radius: 75% 125%;
        border-top-right-radius: 105% 115%;

        &::before {
          content: '';
          position: absolute;
          top: 54%;
          left: -14%;
          width: 60%;
          height: 55%;
          background-color: map.get(var.$colors, 'orange');
        }

        &::after {
          content: '';
          position: absolute;
          top: 69%;
          left: 72%;
          width: 40%;
          height: 40%;
          background-color: map.get(var.$colors, 'orange');
          border-radius: 10%;
        }
      }

      &.pos01 {
        top: 150px;
        left: 1650px;
      }
    }
  }
}

/**
 * Keyframe animations
 */

.float {
  animation-iteration-count: infinite;
  animation-duration: 350s;
  animation-name: float-foreground;
  animation-timing-function: linear;
  will-change: transform;

  @media print {
    animation: none;
  }
}

@keyframes float-foreground {
  0% {
    transform: translate3d(0px, 0, 0);
  }

  100% {
    transform: translate3d(1920px, 0, 0);
  }
}
